import React, { useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';

const MeetingRoom = ({ meetingId }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const connection = useRef(null);
  const peerConnections = useRef({});
  const localStream = useRef(null);
  const screenSharingStream = useRef(null);

  useEffect(() => {
    // Initialize SignalR connection
    connection.current = new signalR.HubConnectionBuilder()
      .withUrl("https://localhost:7201/meetingHub")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.current.start()
      .then(() => {
        console.log("Connected to SignalR");
        connection.current.invoke("JoinMeeting", meetingId);
      })
      .catch(err => {
        console.error("Connection error: ", err);
      });

    // WebRTC signaling events
    connection.current.on("ReceiveOffer", async (fromConnectionId, offer) => {
      await handleReceiveOffer(fromConnectionId, offer);
    });

    connection.current.on("ReceiveAnswer", async (fromConnectionId, answer) => {
      await handleReceiveAnswer(fromConnectionId, answer);
    });

    connection.current.on("ReceiveIceCandidate", async (fromConnectionId, candidate) => {
      await handleReceiveIceCandidate(fromConnectionId, candidate);
    });

    // Handle screen sharing updates
    connection.current.on("UserSharingScreen", (connectionId) => {
      console.log(`${connectionId} is sharing their screen`);
      // UI update to reflect screen sharing
    });

    connection.current.on("UserStoppedSharingScreen", (connectionId) => {
      console.log(`${connectionId} stopped sharing their screen`);
      // UI update to reflect stopping screen sharing
    });

    return () => {
      connection.current.invoke("LeaveMeeting", meetingId);
      connection.current.stop();
      Object.values(peerConnections.current).forEach(pc => pc.close());
    };
  }, [meetingId]);

  const setupWebRTCConnection = async (connectionId) => {
    const pc = new RTCPeerConnection();

    pc.onicecandidate = (event) => {
      if (event.candidate) {
        connection.current.invoke("SendIceCandidate", meetingId, event.candidate, connectionId);
        console.log("a7a",connection.current)
      }
    };

    pc.ontrack = (event) => {
      const remoteStream = event.streams[0];
      const remoteVideo = document.getElementById(`video-${connectionId}`);
      if (remoteVideo) {
        remoteVideo.srcObject = remoteStream;
      } else {
        // If the video element doesn't exist, create one dynamically
        const newVideoElement = document.createElement("video");
        newVideoElement.id = `video-${connectionId}`;
        newVideoElement.autoplay = true;
        newVideoElement.style.width = '300px';
        document.body.appendChild(newVideoElement);
        newVideoElement.srcObject = remoteStream;
      }
    };

    if (localStream.current) {
      localStream.current.getTracks().forEach(track => pc.addTrack(track, localStream.current));
    }

    peerConnections.current[connectionId] = pc;
    return pc;
  };

  const handleReceiveOffer = async (fromConnectionId, offer) => {
    const pc = await setupWebRTCConnection(fromConnectionId);
    await pc.setRemoteDescription(new RTCSessionDescription(offer));

    const answer = await pc.createAnswer();
    await pc.setLocalDescription(answer);

    connection.current.invoke("SendAnswer", meetingId, answer, fromConnectionId);
  };

  const handleReceiveAnswer = async (fromConnectionId, answer) => {
    const pc = peerConnections.current[fromConnectionId];
    await pc.setRemoteDescription(new RTCSessionDescription(answer));
  };

  const handleReceiveIceCandidate = async (fromConnectionId, candidate) => {
    const pc = peerConnections.current[fromConnectionId];
    if (pc) {
      await pc.addIceCandidate(new RTCIceCandidate(candidate));
    }
  };

  const sendMessage = () => {
    if (message.trim() === "") return;

    connection.current.invoke("SendMessage", meetingId, message).catch((err) => {
      console.error("Error sending message: ", err);
      alert("Failed to send message.");
    });
    setMessage(''); // Clear message input after sending
  };

  const shareScreen = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      screenSharingStream.current = screenStream;

      // Replace the current video track with the screen sharing track
      Object.values(peerConnections.current).forEach((pc) => {
        const senders = pc.getSenders();
        const videoSender = senders.find(sender => sender.track.kind === 'video');
        if (videoSender) {
          videoSender.replaceTrack(screenStream.getVideoTracks()[0]);
        } else {
          screenStream.getTracks().forEach(track => pc.addTrack(track, screenStream));
        }
      });

      const videoElement = document.getElementById("screenPreview");
      if (videoElement) {
        videoElement.srcObject = screenStream;
      }

      connection.current.invoke("ShareScreen", meetingId);

      screenStream.getVideoTracks()[0].onended = () => {
        console.log("Screen sharing stopped");
        connection.current.invoke("StopSharingScreen", meetingId);
        stopSharingScreen();
      };
    } catch (error) {
      console.error("Error sharing screen: ", error);
      alert("Screen sharing failed. Please try again.");
    }
  };

  const stopSharingScreen = () => {
    if (screenSharingStream.current) {
      screenSharingStream.current.getTracks().forEach(track => track.stop());
      screenSharingStream.current = null;

      // Revert to local video stream
      Object.values(peerConnections.current).forEach((pc) => {
        const senders = pc.getSenders();
        const videoSender = senders.find(sender => sender.track.kind === 'video');
        if (videoSender && localStream.current) {
          videoSender.replaceTrack(localStream.current.getVideoTracks()[0]);
        }
      });
    }
  };

  return (
    <div>
      <h1>Meeting Room</h1>

      <div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={sendMessage}>Send Message</button>
      </div>

      <div>
        <h2>Chat</h2>
        <div>
          {messages.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
      </div>

      <div>
        <button onClick={shareScreen}>Share Screen</button>
      </div>

      <div>
        <video id="screenPreview" autoPlay muted style={{ width: '300px' }}></video>
      </div>

      {Object.keys(peerConnections.current).map(connectionId => (
        <video key={connectionId} id={`video-${connectionId}`} autoPlay style={{ width: '300px' }}></video>
      ))}
    </div>
  );
};

export default MeetingRoom;
