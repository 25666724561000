import logo from './logo.svg';
import './App.css';
import MeetingRoom from './MeetingRoom';
import VoiceChat from './VoiceChat';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <MeetingRoom meetingId={"6acdb5dc-f130-4095-981b-9437578263c1"}/>
      {/* <VoiceChat meetingId={"6acdb5dc-f130-4095-981b-9437578263c1"}/> */}
    </div>
  );
}

export default App;
